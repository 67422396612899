// 1. Import Bootstrap default functions and variables
@import "bootstrap/scss/functions";
@import "variables.scss";
@import "bootstrap/scss/variables";

// 2. Define our own variables (and Bootstrap variable overrides)
// note that with UF-7375 a consistent Bootstrap theme has been discarded
// and these styles just set classes and colors directly

// 3. Re-define the Bootstrap $theme-colors
$faces-colors: (
  "primary": $faces-main,
  "secondary": #8f8d80,
  "success": $faces-main,
  "danger": #e3527b
);
$theme-colors: map-merge($theme-colors, $faces-colors);
$body-bg: #202124;
$body-color: #ffffff;

// Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap';
@import 'extensions.scss';

:root {
  color-scheme: dark;
}

.app-container {
  .nav-banner {
    background-color: #202124;
  }
}

.navbar-dark {
  .navbar-nav {
    .nav-link {
      color: $faces-main;
    }

    .nav-link.nav-active {
      color: white;
    }
  }
}

.publishing-tab .nav-link.active {
  color: #ffffff;
}

.navigation-outline.active {
  color: $faces-main;
}

h3, h4 {
  color: $faces-main;
}

.col-form-label {
  color: white;
}

.publishing-error {
  color: #e3527b;
}

.publishing-read-pending {
  color:  #eeb20f;
}

.dropdown-menu {
  background-color: #000;
}

.card {
  background-color: #000;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.background-reset {
  background-color: #000;
}

.row-striped:nth-of-type(odd) {
  background: #f9f9f9;
}

/* Overwrite color of collapsed headers */

.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-item {
  .accordion-header {

    background-color: #000;
    border: 1px solid rgba(0, 0, 0, 0.125);

    .accordion-button {
      color: #ffffff;
      background-color: #0d2026;
    }

    .accordion-button:not(.collapsed) {

      background-color: #284c50;
    }
  }
}

.modal-content {
  background-color: #000;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.grid-striped .row:nth-of-type(odd) {
  background-color: rgb(255, 255, 255, 0.05);
}

.alert-danger {
  color: #e3527b;
  background-color: #27050e;
  border-color: #e3527b;

  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e3527b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }
}

.alert-success {
  color: #3bc1ca;
  background-color: #05151a;
  border-color: #3bc1ca;

  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233bc1ca'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }
}

.nav-tabs {
  .nav-link {
    color: #ffffff;
  }

  .nav-link.active {
    color: #3bc1ca;
  }
}

.modal-dialog .modal-content {
  background-color: black;
}

.btn-delete, .btn-danger, .btn-danger:hover  {
  color: #1c6666;
  background-color: white;
  border-color: white;
}

.btn-delete.btn-icon  {
  color: $faces-main;
  background-color: black;
  border-color: black;
}

.btn-primary {
  color: white;
  background-color: $faces-main;
  border-color: $faces-main;
}

.btn-secondary {
  color: $white;
  background-color: #284c50;
  border-color: #284c50;
}

.form-control::placeholder {
  color: #5e8181;
}

.form-control,
.form-select {
  background: black;
  color: $body-color;
  border-color: $faces-main;
  border-width: 1px;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233bc1ca' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233bc1ca' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), none;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px
}

.form-control:disabled,
.form-select:disabled {
  background-color: #1c4848;
  color: #5e8181;
}

div, span {
  .form-control.disabled {
    background-color: #1c4848;
    border-color: #5e8181;
  }
}

.input-group-text {
  background-color: #1c4848;
}

input.ng-dirty,
select.ng-dirty {
  background-color: #10292F;
}

input.ng-invalid,
textarea.ng-invalid,
select.ng-invalid,
app-datepicker.ng-invalid > div > input {
  background-color: #e3526a;
}

input.ng-invalid::placeholder,
textarea.ng-invalid::placeholder,
select.ng-invalid::placeholder,
app-datepicker.ng-invalid > div > input::placeholder {
  color: #3d3d3d;
}

ngb-datepicker {

  .ngb-dp-weekdays,.ngb-dp-header {
    background-color: inherit !important;
  }

  .ngb-dp-week {
    // selected date
    .ngb-dp-day div.btn-light.bg-primary {
      background-color: $faces-main;
    }

    // today
    .ngb-dp-day.ngb-dp-today div.btn-light,
    .ngb-dp-day div.btn-light:hover {
      background-color: #284c50;
    }
  }

}

.error-text {
  color: #27050e;
}

.pseudo-link {
  color: lightblue;
}

.iti__country-list {
  background-color: #000000;
  border-color: #ccc;
}

.iti__dial-code {
  color: #fff;
}

$desktop-width: 1300px;
$card-cap-bg: #fff;
$input-padding-y: 6px;
$input-border-radius: 4px;

$faces-main: #3bc1ca;

// redefine grid breakpoints so that xxl meets our needs
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1300px
);

.input-group .btn {
  padding: $input-group-addon-padding-y $input-group-addon-padding-x;
}

.col-label {
  @extend .col-sm-3;
}

.col-label-wide {
  @extend .col-sm-5;
}

.col-input {
  @extend .col-sm-6;
}

.col-input-wide {
  @extend .col-sm-9;
}

.col-inputwithaction-input {
  @extend .col-sm-3;
}

.col-inputwithaction-action {
  @extend .col-sm-3;
}

.col-actions {
  @extend .col-sm-3;
}

.col-nested-label {
  @extend .col-sm-4;
}

.col-nested-input {
  @extend .col-sm-8;
}

